/* eslint-disable no-undef */
import * as api from './api/index';
import { AMP_CORE_INDICATOR_APP_NAME } from "../core-indicator/utils";
export const LOAD_AMP_SETTINGS = 'LOAD_AMP_SETTINGS';
export const LOAD_AMP_SETTINGS_DONE = 'LOAD_AMP_SETTINGS_DONE';
export const LOAD_AMP_SETTINGS_ERROR = 'LOAD_AMP_SETTINGS_ERROR';
export const LOAD_DATA = 'LOAD_DATA';
export const LOAD_DATA_DONE = 'LOAD_DATA_DONE';
export const LOAD_DATA_ERROR = 'LOAD_DATA_ERROR';
export const loadAmpSettings = () => (dispatch, getState) => {
  const api_root = getState().getIn(['wordpress', 'settings', 'data'])['react_api_url'];
  dispatch({
    type: LOAD_AMP_SETTINGS
  });
  return api._loadAMpSettings(api_root).then(data => {
    return dispatch({
      type: LOAD_AMP_SETTINGS_DONE,
      data: data
    });
  }).catch(error => {
    return dispatch({
      type: LOAD_AMP_SETTINGS_ERROR,
      error
    });
  });
};
export const setDataProvider = ({
  csv,
  store,
  params
}) => (dispatch, getState) => {
  const filters = getState().get('data').getIn(['filters']);
  if (filters) {
    params = {
      ...params,
      ...filters.toJS()
    };
  }
  const data = Papa.parse(csv, {
    header: true
  });
  dispatch({
    type: LOAD_DATA_DONE,
    store,
    data
  });
};
export const getDataProvider = ({
  app,
  source,
  store,
  params,
  measure,
  dateFilter,
  locale,
  keyword
}) => (dispatch, getState) => {
  const filters = getState().get('data').getIn(['filters']);
  const api_root = getState().getIn(['wordpress', 'settings', 'data'])['react_api_url'];
  if (filters) {
    params = {
      ...params,
      ...filters.toJS()
    };
  }
  dispatch({
    type: LOAD_DATA,
    params,
    store
  });
  api.getData(source, params, app, measure, dateFilter, locale, keyword, api_root).then(data => dispatch({
    type: LOAD_DATA_DONE,
    store,
    data
  })).catch(error => dispatch({
    type: LOAD_DATA_ERROR,
    store,
    error
  }));
};
export const loadSearchData = ({
  filters,
  keyword,
  page,
  pageSize,
  store,
  currency,
  isDownload,
  sorting
}) => (dispatch, getState) => {
  const api_root = getState().getIn(['wordpress', 'settings', 'data'])['react_api_url'];
  const locale = 'en';
  let newStore = `${store}${isDownload ? '-download' : ''}`;
  dispatch({
    type: LOAD_DATA,
    store: newStore
  });
  api.searchActivities({
    filters,
    keyword,
    page,
    pageSize,
    currency,
    isDownload,
    locale,
    api_root,
    sorting
  }).then(data => {
    if (data.length > 0) {
      if (sorting) {
        const findPercentageColumnSorted = sorting[0].columns.find(column => column === 'percentage-of-implementation');
        if (findPercentageColumnSorted) {
          calculatePercentageOfImplementation(data, true, sorting[0].asc);
        }
      } else {
        calculatePercentageOfImplementation(data);
      }
    } else {
      if (sorting && sorting.length > 0) {
        const findPercentageColumnSorted = sorting[0].columns.find(column => column === 'percentage-of-implementation');
        if (findPercentageColumnSorted) {
          calculatePercentageOfImplementation(data, true, sorting[0].asc);
        }
      } else {
        calculatePercentageOfImplementation(data);
      }
    }
    return dispatch({
      type: LOAD_DATA_DONE,
      store: newStore,
      data
    });
  }).catch(error => dispatch({
    type: LOAD_DATA_ERROR,
    store: newStore,
    error
  }));
};
export const loadFilters = ({
  filterArray,
  store,
  locale
}) => (dispatch, getState) => {
  const api_root = getState().getIn(['wordpress', 'settings', 'data'])['react_api_url'];
  filterArray.forEach(filter => {
    const newStore = store + '_' + filter;
    dispatch({
      type: LOAD_DATA,
      store: newStore
    });
    api.loadAmpFilters(filter, locale, api_root).then(data => dispatch({
      type: LOAD_DATA_DONE,
      store: newStore,
      data
    })).catch(error => dispatch({
      type: LOAD_DATA_ERROR,
      store: newStore,
      error
    }));
  });
};
function calculatePercentageOfImplementation(data, sorting, asc) {
  const currentDate = new Date();
  data.headers['percentage-of-implementation'] = '% of Implementation';
  data.headers['status'] = 'Status';
  data.data.forEach(project => {
    project['status'] = 'pipeline';
    if (project['actual-start-date'] && project['proposed-completion-date']) {
      const actualStartDate = new Date(project['actual-start-date']);
      const proposedEndDate = new Date(project['proposed-completion-date']);
      const elapsed = currentDate.getTime() - actualStartDate.getTime();
      const totalDuration = proposedEndDate.getTime() - actualStartDate.getTime();
      if (totalDuration > 0) {
        let percentage = elapsed / totalDuration * 100;
        if (percentage > 100) {
          percentage = 100;
        }
        project['percentage-of-implementation'] = percentage.toFixed(2) + ' %';
      } else {
        project['percentage-of-implementation'] = '0.00 %';
      }
      if (currentDate.getTime() > proposedEndDate.getTime()) {
        project['status'] = 'closed';
      }
    } else {
      project['percentage-of-implementation'] = '';
    }
  });
  if (sorting) {
    if (sosrting.asc) {
      data.data.sort((a, b) => {
        if (a['percentage-of-implementation'] && b['percentage-of-implementation']) {
          return parseFloat(a['percentage-of-implementation']) - parseFloat(b['percentage-of-implementation']);
        }
        return 0;
      });
    } else {
      data.data.sort((a, b) => {
        if (a['percentage-of-implementation'] && b['percentage-of-implementation']) {
          return parseFloat(b['percentage-of-implementation']) - parseFloat(a['percentage-of-implementation']);
        }
        return 0;
      });
    }
  }
}
export const loadIndicatorData = ({
  country,
  objectives,
  program,
  objective,
  subPillar,
  filterByProgram,
  filterByDonor,
  donor,
  filterByCountry,
  filterBySubPillar,
  store
}) => (dispatch, getState) => {
  const newStore = `${store}`;
  const api_root = getState().getIn(['wordpress', 'settings', 'data'])['react_api_url'];
  dispatch({
    type: LOAD_DATA,
    store: newStore
  });
  api.fetchIndicators({
    country,
    objectives,
    program,
    objective,
    subPillar,
    filterByCountry,
    filterByProgram,
    filterBySubPillar,
    api_root,
    filterByDonor,
    donor
  }).then(data => dispatch({
    type: LOAD_DATA_DONE,
    store: newStore,
    data
  })).catch(error => dispatch({
    type: LOAD_DATA_ERROR,
    store: newStore,
    error
  }));
};
export const loadCoreIndicatorData = (app, {
  country,
  donor,
  program
}) => (dispatch, getSate) => {
  const store = `${AMP_CORE_INDICATOR_APP_NAME}`;
  const params = {};
  if (country) params.country = country;
  if (donor) params.donor = donor;
  if (program) params.program = program;
  dispatch({
    type: LOAD_DATA,
    store
  });
  api.getCoreIndicatorData(app, params).then(data => dispatch({
    type: LOAD_DATA_DONE,
    store,
    data
  })).catch(error => dispatch({
    type: LOAD_DATA_ERROR,
    store,
    error
  }));
};