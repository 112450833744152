import Example from './embedded/example-project-embedded/';
import Reducers from './embedded/reducers/';
import ProjectList from './embedded/project-list/';
import IndicatorList from "./embedded/indicator-list/";
import TotalWidget from './embedded/totals-widget/';
import TopChart from "./embedded/top-charts";
import CoreIndicator from "./embedded/core-indicator";
const components = {
  example: Example,
  projectList: ProjectList,
  indicatorList: IndicatorList,
  totalWidget: TotalWidget,
  topChart: TopChart,
  coreIndicator: CoreIndicator
};
const getComponentByNameIgnoreCase = name => {
  const k = Object.keys(components).filter(value => value.toLowerCase() == name.toLowerCase());
  return components[k];
};
export { getComponentByNameIgnoreCase, Reducers };