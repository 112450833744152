//import * as api from "../data/api"
import Immutable from 'immutable';
//import { colorSchemes } from "@nivo/colors"
import { getStorageValue, removeStorageValue, setStorageValue } from "./storage";
import * as api from './api/index';
import { LOAD_AMP_SETTINGS, LOAD_AMP_SETTINGS_DONE, LOAD_AMP_SETTINGS_ERROR } from "./data";
const prefix = "app-config.";
const defaultState = {
  'amp-settings': {
    loading: false,
    loaded: false
  }
};
const initialState = Immutable.Map(defaultState);
export default ((state = initialState, action) => {
  switch (action.type) {
    case LOAD_AMP_SETTINGS:
      {
        const store = ['amp-settings'];
        return state.deleteIn([...store, 'error']).setIn(['amp-settings', 'loading'], true);
      }
    case LOAD_AMP_SETTINGS_ERROR:
      {
        const store = ['amp-settings'];
        return state.setIn([...store, 'loading'], false).setIn(['amp-settings', 'error'], action.error);
      }
    case LOAD_AMP_SETTINGS_DONE:
      {
        const store = ['amp-settings'];
        return state.setIn([...store, 'loading'], false).setIn([...store, 'loaded'], true).deleteIn([...store, 'error']).setIn([...store, 'data'], action.data);
      }
    default:
      return state;
  }
});